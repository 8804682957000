/** @jsx jsx */
import { Box, Image, jsx } from "theme-ui";
import React, { useCallback } from "react";
import { PageContentNonMemoized } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";
import DonationLayout from "../layout/DonationLayout";
import { eventCallbackTriggers } from "../templates/wppage";
import bannerImg from "../../static/imgs/forests-img.jpg";

const ForestsFunds = () => {
  const pageContent = (id, modalTitle) => {
    const titletext = modalTitle || "Forests Fund";
    return `<gatsby_donation donationId="${id}" btnText="${titletext}" btnBg=#418808 colortext = black colorprimary = forestgreen colorsecondary = darkgreen colorbackground = white colormuted = #f6f6f6 colorhighlight = #efeffe titletext = "Forests Fund"/>`;
  };

  const PageContent = React.memo(PageContentNonMemoized);

  const eventCallback = useCallback(
    (event) => eventCallbackTriggers("forests-fund", event),
    []
  );
  return (
    <>
      <DonationLayout seoData={{ title: "Heartfulness Forest Funds" }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4 offset-lg-1 d-flex align-content-center flex-wrap">
              <div sx={{ margin: "30px 0 35px" }}>
                <h1
                  sx={{
                    color: "#68a133",
                    fontSize: "92px",
                    fontWeight: "bold",
                    lineHeight: "1",
                  }}
                >
                  Forests
                </h1>
                <h3
                  sx={{
                    fontSize: "26px",
                    fontWeight: "600",
                    paddingLeft: "120px",
                  }}
                >
                  by Heartfulness
                </h3>
                <p sx={{ margin: "30px 30px 10px 0", lineHeight: "1.8" }}>
                  “The one who plants tree, knowing that he will never sit in
                  their shade, has at least started to understand the meaning of
                  life.”
                </p>

                <h5
                  sx={{
                    fontSize: "18px",
                    fontWeight: "600",
                    marginBottom: "25px",
                  }}
                >
                  - Rabindranath Tagore
                </h5>
                <div className="row">
                  <div className="col-md-12 text-center">
                    <PageContent
                      eventCallback={eventCallback}
                      pageContent={pageContent(10, "Contribute Now")}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 offset-lg-1">
              <Box>
                <Image sx={{ backgroundColor: "green" }} src={bannerImg} />
              </Box>
            </div>
          </div>
        </div>
        <div
          sx={{
            backgroundImage: "linear-gradient(#b7d389,#4d8f15)",
            padding: "40px 30px",
            margin: "10px 0px",
            textAlign: "center",
          }}
        >
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <p sx={{ margin: "0px 0px 10px 0px" }}>
                For all queries and interests to contribute / initiate Green
                Kanha Initiatives in collaboration with Heartfulness Institute,
                Please write to
              </p>

              <h4>
                <a sx={{ color: "black" }} href="mailto:info@heartfulness.org">
                  info@heartfulness.org
                </a>
              </h4>
            </div>
          </div>
        </div>
      </DonationLayout>
    </>
  );
};

export default ForestsFunds;
